import Vue from 'vue';
import app from '@/App.vue';
import router from '@/router/router';
import {BootstrapVueIcons, BootstrapVue} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import './assets/custom/myStyle.scss';
import utilityMixin from './utilities/utilityMixin';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.mixin(utilityMixin);
Vue.config.productionTip = false;
new Vue({
	router,
	render: r => r(app)
}).$mount('#app');
