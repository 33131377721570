const DashboardHome = () => import(/* webpackChunkName: "DashboardHome" */ '../pages/DashboardHome.vue');
const Logs = () => import(/* webpackChunkName: "Logs" */ '../pages/Logs.vue');
const Clients = () => import(/* webpackChunkName: "Users" */ '../pages/Clients.vue');

const routes = [
	{
		path: 'dashboard',
		name: 'DashboardHome',
		component: DashboardHome
	},
	{
		path: 'logs',
		name: 'Logs',
		component: Logs
	},
	{
		path: 'clients',
		name: 'Clients',
		component: Clients
	}
];

export default routes;
