import routerDashboard from '../../../components/dashboard/router/router';
const Home = () => import(/* webpackChunkName: "Home" */ '../Home.vue');

let children = [];

children = children.concat(
	routerDashboard
);

const routerHome = [
	{
		path: '/home',
		name: 'Home',
		component: Home,
		children: children
	}
];

export default routerHome;
