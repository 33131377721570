const optionsMsgBox = {
	size: "md",
	noCloseOnEsc: true,
	footerClass: "p-2 border-top-0",
	centered: false
}

export default {
	data() {
		return {
			$optionsMsgBox: optionsMsgBox
		}
	},
	methods: {
		$showMsgBoxDanger: function (title, message, closeOnBackdrop = false) {
			this.$bvModal
				.msgBoxOk(message, {
					title: title,
					headerClass: "vv-msg-header-danger",
					...optionsMsgBox,
					noCloseOnBackdrop: !closeOnBackdrop
				})
				.then()
				.catch();
		},
		$showMsgBoxInfo: function (title, message, closeOnBackdrop = false) {
			this.$bvModal
				.msgBoxOk(message, {
					title: title,
					headerClass: "vv-msg-header-info",
					...optionsMsgBox,
					noCloseOnBackdrop: !closeOnBackdrop
				})
				.then()
				.catch();
		},
		$showMsgBoxSuccess: function (title, message, closeOnBackdrop = false) {
			this.$bvModal
				.msgBoxOk(message, {
					title: title,
					headerClass: "vv-msg-header-success",
					...optionsMsgBox,
					noCloseOnBackdrop: !closeOnBackdrop
				})
				.then()
				.catch();
		},
		$showMsgBoxWarning: function (title, message, closeOnBackdrop = false) {
			this.$bvModal
				.msgBoxOk(message, {
					title: title,
					headerClass: "vv-msg-header-warning",
					...optionsMsgBox,
					noCloseOnBackdrop: !closeOnBackdrop
				})
				.then()
				.catch();
		},
		$vueDatePickerDisabledDateAfterToday(date) {
			return date > new Date(new Date().setHours(0, 0, 0, 0));
		},
		$vueDatePickerDisabledDateBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0));
		}
	}
}
