import Vue from 'vue';
import VueRouter from 'vue-router';
import routerLogin from '@/pages/login/router/routerLogin';
import routerHome from '@/pages/home/router/routerHome';

Vue.use(VueRouter);
let routes = [];
routes = routes.concat(
	routerLogin,
	routerHome,
	[
		{
			path: '/',
			redirect: {name: 'Login'}
		}
	]
);
const router = new VueRouter({routes});

router.beforeEach((to, from, next) => {
	if (to.name === 'Login')
		sessionStorage.clear();
	next();
});

export default router;
