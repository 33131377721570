const Login = () => import(/* webpackChunkName: "Login" */ '../Login.vue');

const routerLogin = [
	{
		path: '/login',
		name: 'Login',
		component: Login
	}
];

export default routerLogin;
